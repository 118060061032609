html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, DM Sans, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    letter-spacing: 0 !important;
    // -webkit-font-smoothing: subpixel-antialiased;
    // font-synthesis: none;
}

@font-face {
    font-family: "Monument Extended";
    src: url("https://app.tiiik.money/static/fonts/MonumentExtended/MonumentExtended-Regular.otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Monument Extended";
    src: url("https://app.tiiik.money/static/fonts/MonumentExtended/MonumentExtended-Ultrabold.otf");
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: "Moderat";
    src: url("https://app.tiiik.money/static/fonts/Moderat/Moderat-Regular.otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Moderat";
    src: url("https://app.tiiik.money/static/fonts/Moderat/Moderat-Bold.otf");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

@media (hover: hover) {
    button:hover, a:hover {
        filter: brightness(80%);
    }
}

.grecaptcha-badge {
    visibility: hidden
}